import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { Link } from "../../components/Link";
import Layout from "../../components/Layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import PageTitle from "../../components/PageTitle";
import {
  ImageInterface,
  InternalLink,
  LocalizedContextInterface,
  PortableText,
  MultiLineHeadline,
  Seo
} from "../../types/SanityTypes";
import SEO from "../../components/Seo";
import StructuredData from "../../components/StructuredData";
import "./styles.scss";
import { LocalisedPageContext } from "../../types/PageTypes";

type SitemapProps = {
  sanitySitemap: {
    banner: {
      headline: MultiLineHeadline;
      primaryColor: {
        title: string;
      };
      _rawShortIntro?: PortableText;
      _rawHeroImage: ImageInterface;
    };
    links: {
      pageName: string;
      ctaLink: InternalLink;
    }[];
    seo: Seo;
    slug: {
      current: string;
    };
  };
} & LocalizedContextInterface;

type SitemapPageContext = LocalisedPageContext;

export const query = graphql`
  query Sitemap($_id: String, $language: String, $market: String) {
    sanitySitemap(_id: { eq: $_id }) {
      banner {
        headline {
          primaryText
          secondaryText
          tertiaryText
        }
        primaryColor {
          title
        }
        _rawShortIntro(resolveReferences: { maxDepth: 8 })
        _rawHeroImage(resolveReferences: { maxDepth: 8 })
      }
      seo {
        metaTitle
        metaDescription
        jsonld
      }
      links {
        pageName
        ctaLink {
          ... on SanitySectionPage {
            _id
            slug {
              current
            }
          }
          ... on SanityArticleFAQ {
            _id
            slug {
              current
            }
          }
          ... on SanityProductLandingPage {
            _id
            slug {
              current
            }
          }

          ... on SanityProductLandingPageV2 {
            _id
            slug {
              current
            }
          }
          ... on SanityContactUs {
            _id
            slug {
              current
            }
          }
          ... on SanityAboutUs {
            _id
            slug {
              current
            }
          }
          ... on SanityLocationSelector {
            _id
            slug {
              current
            }
          }
          ... on SanityLogin {
            _id
            slug {
              current
            }
          }
          ... on SanityNewsletterSignUp {
            _id
            slug {
              current
            }
          }
          ... on SanityNewsletterSignUpEpsilon {
            _id
            slug {
              current
            }
          }
        }
      }
      slug {
        current
      }
    }
    ...LocalizedContext
  }
`;

const Sitemap = (props: PageProps<SitemapProps, SitemapPageContext>) => {
  const sitemap = props.data.sanitySitemap;

  // This is to make sure banner works of internationalisation
  const lineOne =
    sitemap.banner.headline.primaryText.length <= 5
      ? sitemap.banner.headline.primaryText
      : sitemap.banner.headline.primaryText + " ";
  const lineTwo = sitemap.banner.headline.secondaryText === null ? " " : sitemap.banner.headline.secondaryText + " ";
  const lineThree = sitemap.banner.headline.tertiaryText === null ? " " : sitemap.banner.headline.tertiaryText + " ";

  const renderLinks = () => {
    return sitemap.links.map(link => {
      return (
        <li key={link.ctaLink?.slug.current}>
          <Link _id={link.ctaLink?._id} to={link.ctaLink?.slug.current}>
            {link.pageName}
          </Link>
        </li>
      );
    });
  };

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={sitemap.seo.metaTitle}
      pageType={"sitemap"}
    >
      <StructuredData customData={sitemap.seo.jsonld} />
      <SEO
        title={sitemap.seo.metaTitle}
        description={sitemap.seo.metaDescription}
        imageUrl={sitemap.banner._rawHeroImage.asset.url}
        imageAlt={sitemap.banner._rawHeroImage.alt}
      />
      <div className="page_sitemap">
        <Container fluid className="theme_green" data-testid="sitemap">
          <Row>
            <Col>
              <PageTitle
                title={[lineOne, lineTwo, lineThree]}
                image={sitemap.banner._rawHeroImage}
                color={sitemap.banner.primaryColor.title}
              />
            </Col>
          </Row>
          <ul className="columnify">{renderLinks()}</ul>
        </Container>
      </div>
    </Layout>
  );
};

export default Sitemap;
